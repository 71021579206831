<template>
  <base-section id="k-d-a-story-begin" class="white" space="0" top-inner-shadow>
    <base-img
      :src="require('@/assets/bg/bg-white-effect-1.png')"
      :max-height="`${g_bLowerBr || g_bBaseBr ? 900 : 720}px`"
      :height="`${g_bLowerBr || g_bBaseBr ? '850px' : 'fit-content'}`"
      class="py-10"
    >
      <v-container class="fill-height center">
        <v-row>
          <v-col
            cols="12"
            :lg="`${g_bBaseBr ? 6 : 5}`"
            :md="`${g_bBaseBr ? 6 : 5}`"
            sm="12"
            style="flex-direction: column; display: flex; align-items: center"
          >
            <div style="display: flex; flex-direction: column">
              <div class="img-container center">
                <img
                  :src="require('@/assets/photos/story-01.png')"
                  :class="
                    g_bLowestBr ? 'top' : g_bLowerBr ? 'left' : g_bBaseBr ? 'left-base' : 'left'
                  "
                />
              </div>
              <div
                style="align-items: center; display: flex; flex-direction: column; margin-top: 10px"
              >
                <div class="kda-ts-20pt nunito wt-extrabold">Kenneth Nursalim</div>
                <div class="kda-ts-14pt nunito wt-regular">Owner & CEO of KeDA Tech</div>
              </div>
            </div>
          </v-col>
          <v-col v-if="g_bHighestBr" lg="1" />
          <v-col v-else-if="g_bBaseBr" lg="1" />
          <v-col v-else-if="g_bHigherBr" lg="1" />
          <v-col
            cols="12"
            :lg="`${g_bBaseBr ? 5 : 6}`"
            :md="`${g_bBaseBr ? 5 : 6}`"
            sm="12"
            :style="stylesText"
          >
            <div v-for="(item, id) in m_arrContents" :key="id">
              <component
                :is="item.strComponent"
                v-if="item.htmlText"
                :class="item.isCustomStyle ? item.eType : getTextClasses(item.eType)"
                v-html="item.htmlText"
              />
              <component
                :is="item.strComponent"
                v-else-if="item.strText"
                :class="item.isCustomStyle ? item.eType : getTextClasses(item.eType)"
              >
                {{ item.strText }}
              </component>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </base-img>
  </base-section>
</template>

<script>
import TextClass from '@/mixins/text-class';
import BaseBreakpoint from '@/mixins/base-breakpoint';
import BusinessDetails from '@/mixins/business-details';
export default {
  name: 'KDAStoryBegin',

  mixins: [TextClass, BusinessDetails, BaseBreakpoint],

  computed: {
    m_arrContents() {
      return [
        {
          strText: this.$vuetify.lang.t('$vuetify.kda.story.our-story.title'),
          strComponent: 'h1',
          isCustomStyle: true,
          eType: `kda-ts-${this.$vuetify.breakpoint.smAndDown ? 28 : 46}pt wt-black`
        },
        {
          htmlText: this.$vuetify.lang.t('$vuetify.kda.story.our-story.desc'),
          strComponent: 'h5',
          isCustomStyle: true,
          eType: `kda-ts-${this.$vuetify.breakpoint.smAndDown ? 12 : 16}pt nunito wt-regular `
        }
      ];
    },
    stylesText() {
      return {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: `${this.$vuetify.breakpoint.smAndDown ? '0px 20px 30px' : '0px'}`
      };
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

.img-container {
  overflow: hidden;
  position: relative;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  img {
    object-fit: cover;
    &.left {
      height: 460px;
      width: 460px;
    }
    &.left-base {
      height: fit-content;
      width: 100%;
      object-fit: cover;
    }

    &.top {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      position: relative;
      padding: 0px 10px;
    }
  }
}
</style>
